<template>
	<nav class="sidebar-nav admin" v-if="clientNavItems">
		<p class="hidden-item grey">{{ getClientData.clientName }} Overview</p>
		<ul class="sidebar-panel-nav">
			<li class="item" :class="{disabled: item.visible == false}" v-for="item in clientNavItems" v-bind:key="item">
				<router-link :to="{path: item.href}">
					<i class="fa-duotone theme-main" v-bind:class="item.icon"></i>
					<span class="hidden-item">
						{{ item.title }}
					</span>
				</router-link>
			</li>
		</ul>
	</nav>
</template>
<script>
import {mapGetters} from "vuex"

export default {
	data() {
		return {
			id: "clientNav",
			clientNavItems: [],
		}
	},
	created() {
		this.clientNavItems = [
			{href: `/client/${this.getAuthFirstAssignedClient}/dashboard`, title: "Dashboard", icon: "fa-grid-2", visible: this.getAuthFirstAssignedClient},
			{href: `/client/${this.getAuthFirstAssignedClient}/content`, title: "Content", icon: "fa-quote-left", visible: this.getAuthFirstAssignedClient},
			{href: `/client/${this.getAuthFirstAssignedClient}/paid-social`, title: "Paid Social", icon: "fa-cart-shopping", visible: false},
			{href: `/client/${this.getAuthFirstAssignedClient}/organic-social`, title: "Organic Social", icon: "fa-heart", visible: false},
			{href: `/client/${this.getAuthFirstAssignedClient}/analytics`, title: "Analytics", icon: "fa-chart-column", visible: false},
			{href: `/client/${this.getAuthFirstAssignedClient}/seo`, title: "SEO", icon: "fa-eye", visible: false},
			{href: `/client/${this.getAuthFirstAssignedClient}/ppc`, title: "PPC", icon: "fa-arrow-pointer", visible: false},
		]
	},
	computed: {
		...mapGetters("client", ["getClientData"]),
		...mapGetters("auth", ["getAuthFirstAssignedClient"]),
	},
}
</script>
