<template>
	<div class="search">
		<form id="filter-search" class="filter-search" :class="{'has-content': hasTyped}" autocomplete="off">
			<div class="styled-select" id="date" @keyup="setSearchContent($event)">
				<VueNextSelect @selected="this.$emit('selected', $event)" id="selectedDate" :options="options" searchable :label-by="labelBy" :modelValue="[modelValue]" close-on-select :placeholder="placeHolder"></VueNextSelect>
			</div>
		</form>
	</div>
</template>

<script>
import VueNextSelect from "vue-next-select"
import "vue-next-select/dist/index.min.css"
export default {
	name: "HideFromUser",
	props: {
		options: Object,
		labelBy: Function,
		placeHolder: String,
		modelValue: Object,
	},
	data() {
		return {
			hasTyped: false,
		}
	},
	components: {
		VueNextSelect,
	},
	methods: {
		setSearchContent() {
			this.hasTyped = true
		},
	},
}
</script>
<style scoped>
@import "../../../../node_modules/vue-next-select/dist/index.min.css";
</style>
