<template>
	<div class="user-actions">
		<nav class="actions">
			<ul>
				<template v-if="can(getAuthData, 'access_global_admin_view')">
					<!-- if is Admin -->
					<li class="action" v-if="$route.name === 'Client Content'">
						<router-link :to="{path: `/client/${getClientData.slug}/dashboard/`}">
							<i class="fa-duotone fa-grid-2 theme-main"></i>
							<span class="hidden-item">Dashboard</span>
						</router-link>
					</li>
					<!-- <li class="action" v-else>
						<router-link :to="{path: `/client/${getClientData.slug}/edit/`}">
							<i class="fad fa-cog theme-main"></i>
							<span class="hidden-item">Settings</span>
						</router-link>
					</li> -->
					<li class="action" v-if="$route.path.includes('/client/')">
						<router-link :to="{path: `/admin/dashboard/`}">
							<i class="fa-duotone fa-pen-to-square theme-main"></i>
							<span class="hidden-item">Admin area</span>
						</router-link>
					</li>
				</template>
				<template v-else>
					<li class="action" v-if="$route.name === 'Client Dashboard'">
						<router-link :to="{path: `/client/${getClientData.slug}/edit/`}">
							<i class="fa-duotone fa-gear theme-main"></i>
							<span class="hidden-item">Settings</span>
						</router-link>
					</li>
					<li class="action" v-else>
						<router-link :to="{path: `/client/${getClientData.slug}/dashboard/`}">
							<i class="fa-duotone fa-grid-2 theme-main"></i>
							<span class="hidden-item">Dashboard</span>
						</router-link>
					</li>
				</template>
				<li class="action">
					<div @click="signOut">
						<i class="fa-duotone fa-arrow-right-from-bracket theme-main"></i>
						<span class="hidden-item">Sign Out</span>
					</div>
				</li>
			</ul>
		</nav>
	</div>
	<!-- end user-actions -->
</template>
<script>
//import axios from 'axios';
import {mapGetters} from "vuex"

export default {
	data() {
		return {}
	},
	computed: {
		...mapGetters("auth", ["getAuthData", "getAuthRoleName", "getAuthRoleName"]),
		...mapGetters("client", ["getClientData"]),
		isAdmin() {
			return this.getAuthRoleName === "administrator" || this.getAuthRoleName === "operator"
		},
	},
	props: ["clientSlug"],
	methods: {
		async signOut() {
			await this.$store.dispatch("auth/logOut")
			this.$router.push("/login")
		},
	},
}
</script>
