<template>
	<aside class="sidebar">
		<div class="close" v-bind:title="[!getSidebarOpenState ? 'Open Sidebar' : 'Close Sidebar']" v-on:click="toggleSidebar()">
			<i class="fas fa-chevron-left"></i>
		</div>
		<!-- end close -->
		<div class="sidebar-panel">
			<div class="sidebar-top">
				<div class="user-info">
					<router-link :to="{path: `/user/${getAuthData.username}/edit/`}">
						<div class="profile-image" v-if="getAuthAvatarUrl">
							<img v-bind:src="getAuthAvatarUrl" v-bind:alt="getAuthFullName" v-bind:title="getAuthFullName" />
						</div>
						<!-- end profile-image -->
						<div class="info-col hidden-item">
							<p class="name">{{ getAuthFullName }}</p>
							<p class="occupation">{{ getAuthRoleName }}</p>
						</div>
						<!--end info-col -->
					</router-link>
				</div>
				<!-- end user-info -->

				<template v-if="can(getAuthData, 'access_global_admin_view')">
					<template v-if="$route.name === 'Client Dashboard'">
						<ClientNav></ClientNav>
					</template>
					<template v-else-if="$route.name === 'Edit Client'">
						<ClientNav></ClientNav>
					</template>
					<template v-else-if="$route.name === 'Client Content'">
						<ContentTreeFilters @selectNode="nodeSelectedInSearch" v-on:click="openClosedSidebar()"></ContentTreeFilters>
					</template>
					<template v-else>
						<AdminNav></AdminNav>
					</template>
				</template>
				<template v-else>
					<template v-if="$route.name === 'Client Content'">
						<ContentTreeFilters v-on:click="openClosedSidebar()"></ContentTreeFilters>
					</template>
					<template v-else>
						<ClientNav></ClientNav>
					</template>
				</template>
			</div>
			<!-- end sidebar-top -->
			<UserActions></UserActions>
		</div>
		<!-- end sidebar-panel -->
	</aside>
</template>
<script>
import UserActions from "@/components/sidebar/UserActions.vue"
import AdminNav from "@/components/sidebar/AdminNav.vue"
import ClientNav from "@/components/sidebar/ClientNav.vue"
import ContentTreeFilters from "@/components/sidebar/ContentTreeFilters.vue"
import {mapGetters} from "vuex"

//let main = null
let sidebarWrapper = null

export default {
	data() {
		return {
			profilePic: "",
			// userName: this.$store.state.userData.username,
			userglobalRoleId: "Administrator",
			// userOccupation: this.$store.state.userData.id,
			loginType: "client",
		}
	},
	computed: {
		...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("auth", ["getAuthData", "getAuthRoleName", "getAuthRoleName", "getAuthAvatarUrl", "getAuthFullName"]),
		isAdmin() {
			return this.getAuthRoleName === "administrator" || this.getAuthRoleName === "operator"
		},
	},
	props: ["clientSlug"],
	components: {
		UserActions,
		AdminNav,
		ClientNav,
		ContentTreeFilters,
	},
	methods: {
		nodeSelectedInSearch() {
			// console.log("sidebar: nodeSelectedInSearch")
			this.$emit("nodeSelectedInSearch")
		},
		toggleSidebar() {
			this.$store.dispatch("views/toggleSidebar")
			if (JSON.parse(this.getSidebarOpenState)) {
				sidebarWrapper.classList.remove("closed")
				sidebarWrapper.classList.add("open")
			} else {
				sidebarWrapper.classList.remove("open")
				sidebarWrapper.classList.add("closed")
			}
		},
		openClosedSidebar() {
			this.$store.dispatch("views/toggleSidebar")
			if (JSON.parse(this.getSidebarOpenState)) {
				sidebarWrapper.classList.remove("closed")
				sidebarWrapper.classList.add("open")
			}
		},
		// getSelectedDate(value) {
		// 	console.log(value)
		// },
	},
	mounted() {
		sidebarWrapper = document.getElementById("sidebarWrapper")
		this.$store.dispatch("views/setSidebar")
		if (JSON.parse(this.getSidebarOpenState) == true) {
			sidebarWrapper.classList.remove("closed")
			sidebarWrapper.classList.add("open")
		} else {
			sidebarWrapper.classList.remove("open")
			sidebarWrapper.classList.add("closed")
		}
	},
}
</script>
<style lang="scss">
@import "./src/assets/scss/components/sidebar.scss";
</style>
